import React from "react";
import { Link } from "react-router-dom";
import Modal from "./modal";

function DataCard(props) {
  const {
    id,
    waterMark,
    title,
    content,
    modalStatus,
    modalDetials,
  } = props.card;

  const closeModal = (id) => {
    props.handleModal(id);
  };
  return (
    <>
      <div className="dataCard">
        <div className="cardBox">
          <div className="cardContent">
            <h2>{waterMark}</h2>
            <h3>{title}</h3>
            <p>{content}</p>
            <Link to="./data-analytics" onClick={() => props.handleModal(id)}>
              Read More{"  "}
              <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>
            </Link>
          </div>
        </div>
      </div>
      <Modal
        status={modalStatus}
        id={id}
        title={title}
        details={modalDetials}
        closeModal={closeModal}
      />
    </>
  );
}

export default DataCard;
