import React from "react";

function Box(props) {
  const { logo, title, content } = props.box;
  return (
    <div className="box">
      <div className="boxFront">
        <h2>
          <i className={logo} aria-hidden="true"></i>
        </h2>
        <p>{title}</p>
      </div>
      <div className="boxBack">
        <p>{content}</p>
      </div>
    </div>
  );
}

export default Box;
