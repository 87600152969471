const animatedCardData = [
    {
        id: 1,
        title: "SQL",
        watermark: "SQL",
        subtitle: "Relational Database",
        content: `Excellent coder of SQL, able to handle basic and complex CRUD operations (DDL and DML). 
                Experienced with building conceptual data model (e.g. ERD), logical and physical model 
                for small and medium orgnizations. Great understanding of advanced concepts of 
                functions, views, triggers, events and indexing etc.`
    },
    {
        id: 2,
        title: "NoSQL",
        watermark: "NoSQL",
        subtitle: "Non-Relational Database",
        content: `Fluent coder of MongoDB, deep understanding of how a non-relational database works. Able
                to connect MongoDB to React and node project, handle CRUD operations using Mongoose, perform
                data validation, and model relationships between connected data. Familiar with other NoSQL like
                Google Firebase...`
    }
]

export default animatedCardData;