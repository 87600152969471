import React from "react";
import close from "../../assets/close.png";

function Modal({ status, id, closeModal, title, details }) {
  return (
    <div className={`dataCardModal ${status && "active"}`}>
      <h2>{title}</h2>
      <p>{details}</p>
      <img
        src={close}
        alt="X"
        className="close"
        onClick={() => closeModal(id)}
      />
    </div>
  );
}

export default Modal;
