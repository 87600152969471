import React from "react";

function Block() {
  return (
    <div className="block">
      <h3>HTML</h3>
      <h3>SCSS</h3>
      <h3>node.js</h3>
      <h3>SQL</h3>
      <h3>NoSQL</h3>
    </div>
  );
}

export default Block;
