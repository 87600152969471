import React, { useContext } from "react";
import Header from "./../home/header";
import Menu from "./../home/menu";
import { MenuContext } from "../../App";
import FormBox from "./formBox";
import Info from "./info";

function Contact() {
  // get context value
  const menuContext = useContext(MenuContext);
  const menu = menuContext.state.toggleActive;
  return (
    <div className={`contact ${menu && "active"}`}>
      <Header />
      <div className="container">
        <div className="form-box">
          <FormBox />
        </div>
        <Info />
      </div>
      <Menu />
    </div>
  );
}

export default Contact;
