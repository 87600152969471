import React from "react";
import { Link } from "react-router-dom";

function SciIcon() {
  return (
    <ul className="sci">
      <li>
        <Link to="/">
          <i className="fa fa-facebook" aria-hidden="true"></i>
        </Link>
      </li>
      <li>
        <Link to="/">
          <i className="fa fa-twitter" aria-hidden="true"></i>
        </Link>
      </li>
      <li>
        <Link to="/">
          <i className="fa fa-instagram" aria-hidden="true"></i>
        </Link>
      </li>
      <li>
        <a target="_blank" href="https://www.linkedin.com/in/david-fan-048b5168/">
          <i className="fa fa-linkedin" aria-hidden="true"></i>
        </a>
      </li>
    </ul>
  );
}

export default SciIcon;
