import React, { useState } from "react";
import emailjs from 'emailjs-com';

function FormBox() {
  const intialState = {
    fullname: "",
    email: "",
    message: "",
    result: ""
  };
  const [text, setText] = useState(intialState);

  const changeText = (e) => {
    const { name, value } = e.target;
    setText({ ...text, [name]: value, result: "" });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (text.fullname === "" || text.email === "" || text.message === "") {
      setText({ ...text, result: "Please enter all above details!" })
      return;
    }
    // show pending info while sending email
    setText({ ...text, result: "Sending Message..." });
    emailjs.sendForm('service_xwv2jse', 'template_x9knm1b', e.target, 'user_Ha1gg5nd0gZPFg2yHbhTE')
      .then((result) => {
        //console.log(result.text);
        setText({ ...intialState, result: "Thank you, message sent successfully!" });
      }, (error) => {
        //console.log(error.text);
        setText({ ...intialState, result: "Something went wrong" });
      });
  }

  return (
    <div className="box">
      <div className="form">
        <h2>Contact me</h2>
        <form onSubmit={sendEmail}>
          <div className="inputBox">
            <input
              type="text"
              name="fullname"
              value={text.fullname}
              onChange={changeText}
              placeholder="Full Name"
            />
            <div className="icon">
              <i className="fa fa-user" aria-hidden="true"></i>
            </div>
          </div>
          <div className="inputBox">
            <input
              name="email"
              type="email"
              value={text.email}
              onChange={changeText}
              placeholder="Email"
            />
            <div className="icon">
              <i className="fa fa-envelope" aria-hidden="true"></i>
            </div>
          </div>
          <div className="inputBox">
            <textarea
              type="text"
              name="message"
              value={text.message}
              onChange={changeText}
              rows="5"
              placeholder="Please leave a message ..."
            />
            <div className="icon">
              <i className="fa fa-commenting" aria-hidden="true"></i>
            </div>
          </div>
          <div className="inputBox">
            <input className="submitBtn" type="submit" value="Submit" />
          </div>
        </form>
        <p>{text.result}</p>
      </div>
    </div>
  );
}

export default FormBox;
