import React from "react";

function ContentCard(props) {
  const { icon, title, content } = props.card;
  return (
    <div className="contentCard">
      <div className="icon">
        <i className={icon} aria-hidden="true"></i>
      </div>
      <div className="content">
        <h3 className="title">{title}</h3>
        <p>{content}</p>
      </div>
    </div>
  );
}

export default ContentCard;
