import React, { useRef, useEffect } from "react";
import demoVideo from "../../assets/profile.mp4";

function Video({ status }) {
  const videoRef = useRef(null);

  const pauseVideo = () => {
    status ? videoRef.current.play() : videoRef.current.pause();
  };
  useEffect(() => {
    pauseVideo();
  });
  return (
    <video
      ref={videoRef}
      src={demoVideo}
      controls={true}
      autoPlay={true}
    ></video>
  );
}

export default Video;
