import React, { useContext } from "react";
import close from "../../assets/close.png";
import { MenuContext } from "../../App";
import Video from "./video";

function Trailer() {
  // get context value
  const menuContext = useContext(MenuContext);
  const video = menuContext.state.toggleVideo;

  const toggleVideo = () => {
    menuContext.dispatch({ type: "toggle-video", payload: !video });
  };

  return (
    <div className={`trailer ${video && "active"}`}>
      <Video status={video} />
      <img className="close" src={close} alt="close" onClick={toggleVideo} />
    </div>
  );
}

export default Trailer;
