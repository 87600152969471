const contentCardData = [
  {
    id: 1,
    icon: "fa fa-cog",
    title: "node.js",
    content: `Fluent coder of node, able to develop RESTful Apis with MERN style (mongoose, express.js, 
                react.js and node.js). Good knowledge of routes security, authentication, authorization (JWT),
                input validation, data encryption...`,
  },
  {
    id: 2,
    icon: "fa fa-wrench",
    title: "php",
    content: `Good at combining php with SQL and frontend frameworks (React.js and Vue.js). Able to create RESTful Apis with native php. 
                Deep understanding of how to parse data response into JSON. Familiar with
                php web frameworks (e.g. Laravel and CodeIgniter).`,
  },
  {
    id: 3,
    icon: "fa fa-connectdevelop",
    title: "python",
    content: `Fluent coder of python. Able to build RESTful Apis. Familar with popular
                python web frameworks (e.g. Flask and Django). Deep understanding of object oriented
                programming for web development.`,
  },
  {
    id: 4,
    icon: "fa fa-diamond",
    title: "java",
    content: `Good programmer of Java. Familiar with Java web development framework (e.g. Spring).
                Great knowledge of OOP concept in Java, including encapsulation, inheritance and 
                polymorphism etc.`,
  },
];

export default contentCardData;
