import React, { useContext } from "react";
import { MenuContext } from "../../App";
import { Link } from "react-router-dom";

function Header() {
  // get context value
  const menuContext = useContext(MenuContext);
  const menu = menuContext.state.toggleActive;

  return (
    <header>
      <Link to="/" className="logo">
        D Studio
      </Link>
      <div
        className={`toggle ${menu && "active"}`}
        onClick={() => {
          menuContext.dispatch({ type: "toggle", payload: !menu });
        }}
      ></div>
    </header>
  );
}

export default Header;
