import React, { useContext } from "react";
import profile from "../../assets/profile.png";
import play from "../../assets/play.png";
import Header from "./../home/header";
import Menu from "./../home/menu";
import { MenuContext } from "../../App";
import CircleCard from "./circleCard";
import cardData from "./cardData";
import Trailer from "./trailer";

function About() {
  // get context value
  const menuContext = useContext(MenuContext);
  const menu = menuContext.state.toggleActive;
  const video = menuContext.state.toggleVideo;

  const toggleVideo = () => {
    menuContext.dispatch({ type: "toggle-video", payload: !video });
  };

  return (
    <div className={`about ${menu && "active"}`}>
      <Header />
      <Menu />
      <div className="content">
        <div className="profile">
          <div className="profile-img">
            <img src={profile} alt="Profile" />
          </div>
          <h2>Hi, I'm David</h2>
          <p>
            A self-motivated app developer and data analyst, passionate about
            creating applications and digging values behind data. Years of
            training and developing experience ensures my edge-cutting technical
            skills at: Excel, SQL, Python DB Libraries, R, PowerBI, Stata, SSIS,
            MS SQL server, ETL. UI/UX designing skills with Adobe Ps, Adobe Ai,
            Adobe Xd; Video editing skills with Adobe Pr. Full stack programming
            skills in HTML5, CSS/Scss, javaScript (&gt; ES6), React.js, Vue.js,
            Redux, node.js, php, python (Flask &amp; Django) and Java. Able to
            handle both SQL (e.g. MySQL) and NoSQL (e.g. MongoDB)
          </p>
          <div className="play" onClick={toggleVideo}>
            <img src={play} alt="play" />
            watch me
          </div>
        </div>
        <div className="skills">
          {cardData.map((card) => (
            <CircleCard
              key={card.id}
              color={card.color}
              title={card.title}
              percentage={card.percentage}
              content={card.content}
            />
          ))}
        </div>
      </div>
      <Trailer />
    </div>
  );
}

export default About;
