import React, { useContext } from "react";
import { MenuContext } from "../../App";
import { NavLink } from "react-router-dom";
import Copyright from '../contact/copyright';

function Menu() {
  // get context value
  const menuContext = useContext(MenuContext);
  const removeMenu = () => {
    menuContext.dispatch({ type: "toggle", payload: false });
  };
  return (
    <ul className="menu">
      <li>
        <NavLink to="/" onClick={removeMenu}>
          <i className="fa fa-home" aria-hidden="true"></i>
        </NavLink>
      </li>
      <li>
        <NavLink to="/about" onClick={removeMenu}>
          About me
        </NavLink>
      </li>
      <li>
        <NavLink to="/frontend" onClick={removeMenu}>
          Frontend
        </NavLink>
      </li>
      <li>
        <NavLink to="/backend" onClick={removeMenu}>
          Backend
        </NavLink>
      </li>
      <li>
        <NavLink to="/data-analytics" onClick={removeMenu}>
          Data analytics
        </NavLink>
      </li>
      <li>
        <NavLink to="/contact" onClick={removeMenu}>
          Contact me
        </NavLink>
      </li>
      <Copyright />
    </ul>
  );
}

export default Menu;
