import React from "react";
import SciIcon from "./../home/sciIcon";
import Copyright from './copyright';

function Info() {
  return (
    <div className="info">
      <div className="details">
        <h2>Thank You</h2>
        <p>
          Thanks for visiting my page! I am a app developer and data analyst who is super
          passionate about resolving real world issues. With infinite curiorsity, inspiration and perspiration,
          I am constantly on the way of approaching beyond and excited to embark upon a journey
          with you if you allow me to join. <br /> <br />
          <cite>- to infinity and beyond</cite>
        </p>
        <h2>Contact Details</h2>
        <p>
          <i className="fa fa-user-o" aria-hidden="true"></i> Developer: David
          Fan
        </p>
        <p>
          <i className="fa fa-envelope-o" aria-hidden="true"></i> Email:
          <a href="mailto:davidfan618@gmail.com"> davidfan618@gmail.com</a>
        </p>
        <p>
          <i className="fa fa-map-marker" aria-hidden="true"></i> Location:
          Brisbane, Australia
        </p>
      </div>
      <SciIcon />
      <Copyright />
    </div>
  );
}

export default Info;
