const cardData = [
    {
        id: 1,
        title: "UI/UX Design",
        content: "Innovative thinking, creative designing, proficient skills, guaranteed user experience",
        color: "#e80202",
        percentage: 90
    },
    {
        id: 2,
        title: "Backend Development",
        content: "Accurate data model, efficient database, effective and secured RESTful Apis",
        color: "#aeff0f",
        percentage: 88
    },
    {
        id: 3,
        title: "Data Analytics",
        content: "Excel, Python and R, ETL approach, effective data mining, high quality reports",
        color: "#00a1ff",
        percentage: 92
    }
]

export default cardData;