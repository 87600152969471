import React from "react";

function CircleCard({ title, content, color, percentage }) {
  const circlePercent = 352 - (352 * percentage) / 100;
  const circleStyle = {
    strokeDasharray: 352,
    strokeDashoffset: circlePercent,
    stroke: color,
  };
  return (
    <div className="card">
      <div className="box">
        <div className="percentage">
          <svg>
            <circle cx="56" cy="56" r="56"></circle>
            <circle style={circleStyle} cx="56" cy="56" r="56"></circle>
          </svg>
          <div className="number">
            <h2>
              {percentage}
              <span>%</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="content">
        <h2 className="text">{title}</h2>
        <p>{content}</p>
      </div>
    </div>
  );
}

export default CircleCard;
