const boxData = [
  {
    id: 1,
    logo: "fa fa-html5",
    title: "HTML",
    content: `Proficient coder with HTML5, deep understanding in most popular tags, able to code under
                plain HTML environment and other frontend frameworks, confident to structure and present 
                any web components on the DOM for responsive applications.`,
  },
  {
    id: 2,
    logo: "fa fa-desktop",
    title: "Adobe Ps",
    content: `Fluent user of Adobe Ps, able to handle complex image processing and modificaiton, 
                design promotional business poster and flyer.`,
  },
  {
    id: 3,
    logo: "fa fa-laptop",
    title: "Adobe Xd",
    content: `Excellent user of Adobe XD, able to design customised and diversified prototype for web
                and mobile application, with creative animations.`,
  },
  {
    id: 4,
    logo: "fa fa-css3",
    title: "CSS/SCSS",
    content: `Advanced user of css/scss, good at responsive design with innovative 2D and 3D 
                animations, confident to make any prototype components alive.`,
  },
  {
    id: 5,
    logo: "fa fa-handshake-o",
    title: "Hi, CHECK OUT MY FRONTEND SKILLS",
    content: `Welcome to see my frontend skills. I'm a designer with creative thinking and genuine design passion. 
                Talented at constructing dynamic components that not only stand outside box but perfectly fit your scenario. 
                I have the natural capacity to raise amazing design ideas that click your minds.`,
  },
  {
    id: 6,
    logo: "fa fa-tablet",
    title: "Adobe Ai",
    content: `Fluent user of Adobe Ai, able to desigin amazing logos, posters, flyers and info graphics for
                businesses in multiple industries.`,
  },
  {
    id: 7,
    logo: "fa fa-video-camera",
    title: "Abode Pr",
    content: `Good user of Adobe Pr, able to create original videos for promotion and illustration. Also good at
                editing videos to deliver high quality presentation.`,
  },
  {
    id: 8,
    logo: "fa fa-code",
    title: "React.js/Vue.js",
    content: `Favourate frontend javaScript library. Fluent user of React.js, Vue.js, React Hooks, Redux. 
                Experienced with many practical node packages. Confident to render at will.`,
  },
  {
    id: 9,
    logo: "fa fa-file-code-o",
    title: "javaScript",
    content: `Advanced javaScript programmer with deep understanding of both basics and advanced OOP.
                Great experience with object, array, function, prototypes, ES6 module and ES6 class. Able to build amazing web
                application with vanilla javaScript, html and css.`,
  },
];

export default boxData;
