import React, { useContext, useState } from "react";
import Header from "./../home/header";
import { MenuContext } from "../../App";
import DataCard from "./dataCard";
import dataCardData from "./dataCardData";
import Menu from "./../home/menu";

function DataAnalytics() {
  // get context value
  const menuContext = useContext(MenuContext);
  const menu = menuContext.state.toggleActive;
  const [data, setData] = useState(dataCardData);

  const toggleModal = (id) => {
    const newData = data.map((card) => {
      if (card.id === id) card.modalStatus = !card.modalStatus;
      return card;
    });
    setData(newData);
  };
  return (
    <div className={`data-analytics ${menu && "active"}`}>
      <Header />
      <div className="title">
        <h1>Data Analytics</h1>
        <p>Five years experience as data analytics training specialist. Strong knowledge base of statistic and
        econometrics. Proficient skills in Excel, PowerBI, SQL, python DB libraries, R, Stata and ETL/data
        warehouse approach with MS SQL and SSIS.
        </p>
      </div>
      <div className="content">
        {data.map((card) => (
          <DataCard key={card.id} card={card} handleModal={toggleModal} />
        ))}
      </div>
      <Menu />
    </div>
  );
}

export default DataAnalytics;
