import React, { useReducer } from "react";
import { Route, Switch } from "react-router-dom";
import Banner from './components/home/banner';
import About from './components/about/about';
import Contact from './components/contact/contact';
import DataAnalytics from './components/dataAnalytics/dataAnalytics';
import Backend from './components/backend/backend';
import Frontend from './components/frontend/frontend';
import './App.scss';

//create a context
export const MenuContext = React.createContext();

//create initial state
const initialState = {
  toggleActive: false,
  toggleVideo: false,
}

// reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case "toggle":
      return { ...state, toggleActive: action.payload }
    case "toggle-video":
      return { ...state, toggleVideo: action.payload }
    default:
      return state
  }
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <MenuContext.Provider value={{ state: state, dispatch: dispatch }}>
      <>
        <Switch>
          <Route path="/contact" exact component={Contact} />
          <Route path="/data-analytics" exact component={DataAnalytics} />
          <Route path="/backend" exact component={Backend} />
          <Route path="/frontend" exact component={Frontend} />
          <Route path="/about" exact component={About} />
          <Route path="/" exact component={Banner} />
        </Switch>
      </>
    </MenuContext.Provider>

  );
}

export default App;
