import React, { useContext } from "react";
import Header from "./../home/header";
import Menu from "./../home/menu";
import { MenuContext } from "../../App";
import Box from "./box";
import boxData from "./boxData";

function Frontend() {
  // get context value
  const menuContext = useContext(MenuContext);
  const menu = menuContext.state.toggleActive;
  return (
    <div className={`frontend ${menu && "active"}`}>
      <Header />
      <div className="container">
        {boxData.map((box) => (
          <Box key={box.id} box={box} />
        ))}
      </div>
      <Menu />
    </div>
  );
}

export default Frontend;
