import React, { useContext, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import anime from "animejs/lib/anime.es.js";
import Block from "./block";
import Menu from "./menu";
import Header from "./header";
import { MenuContext } from "../../App";
import SciIcon from "./sciIcon";

function Banner() {
  // get context value
  const menuContext = useContext(MenuContext);
  const menu = menuContext.state.toggleActive;

  const blocks = useMemo(() => {
    let blockList = [];
    for (let i = 0; i < 56; i++) {
      blockList.push(i);
    }
    return blockList;
  }, []);

  const animateBlocks = () => {
    anime({
      targets: ".block",
      translateX: () => anime.random(-700, 700),
      translateY: () => anime.random(-500, 500),
      scale: () => anime.random(1, 5),
      easing: "linear",
      duration: 3000,
      delay: anime.stagger(10),
      complete: animateBlocks,
    });
  };

  useEffect(() => {
    animateBlocks();
    // const blockInterval = setInterval(() => {
    //   animateBlocks();
    // }, 3000);
    // return () => {
    //   clearInterval(blockInterval);
    // };
  }, []);

  return (
    <div className={`banner ${menu && "active"}`}>
      <Header />
      <h2>
        <span>Full Stack Developer</span> <br />
        David Fan
      </h2>
      <div className="btn">
        <Link to="/about">Find out more</Link>
      </div>
      {blocks.map((block) => (
        <Block key={block} />
      ))}
      <Menu />
      <SciIcon />
    </div>
  );
}

export default Banner;
