import React, { useContext } from "react";
import Header from "./../home/header";
import Menu from "./../home/menu";
import { MenuContext } from "../../App";
import ContentCard from "./contentCard";
import AnimatedCard from "./animatedCard";
import contentCardData from "./contentCardData";
import animatedCardData from "./animatedCardData";

function Backend() {
  // get context value
  const menuContext = useContext(MenuContext);
  const menu = menuContext.state.toggleActive;
  return (
    <div className={`backend ${menu && "active"}`}>
      <Header />
      <div className="container">
        <div className="left">
          {contentCardData.map((card) => (
            <ContentCard key={card.id} card={card} />
          ))}
        </div>
        <div className="right">
          {animatedCardData.map((card) => (
            <AnimatedCard key={card.id} card={card} />
          ))}
        </div>
      </div>
      <Menu />
    </div>
  );
}

export default Backend;
