const dataCardData = [
    {
        id: 1,
        waterMark: "01",
        title: "Excel/Power BI",
        content: `Proficient user of MS Excel and Power BI, able to apply advanced features 
                and formulas to manipulate and present data, data visualisation...`,
        blurColorBeg: "#e80202",
        blurColorEnd: "#e64a19",
        modalDetials: `Proficient user of MS Excel and Power BI, able to apply advanced features 
        and formulas to manipulate and present data, handle dynamic data visualisation that meet clients'
        demand. Good at analysing sale data, accounting data, financial data, and client data. Experienced
        with capital budgeting projects, inventory management, client relationship management, seasonal sales projection, 
        financial statement analysis, stock market technical analysis, risk management (value at risk analysis) and investment portfolio analysis...`,
        modalStatus: false
    },
    {
        id: 2,
        waterMark: "02",
        title: "Python",
        content: `Fluent python coder, experienced with major Anaconda python data scicence libraries (e.g.)
                Pandas, Matplotlib, and Numpy. Data analytics with Jupyter notebook...`,
        blurColorBeg: "#ff0000",
        blurColorEnd: "#ffc107",
        modalDetials: `Fluent python coder, experienced with major Anaconda python data scicence libraries (e.g.)
        Pandas, Matplotlib, and Numpy. Skilled with Jupyter notebook for data analytics. Performing data staging tasks
        like data importing, exporting, filtering, cleaning, sorting, grouping and combination. Implementing data visualisation
        like dynamic plotting and charting. Handling complex array operation. Familiar with popular math and statistic methods.
        Experienced with stock market technical analysis, and risk management (value at risk), investment portfolio management...`,
        modalStatus: false
    },
    {
        id: 3,
        waterMark: "03",
        title: "R",
        content: `Fluent coder of R, familiar with popluar packages like pacman, tidyverse etc. Able
        to handle data manipulation, visualisation and data mining...`,
        blurColorBeg: "#e91e63",
        blurColorEnd: "#5602ff",
        modalDetials: `Fluent coder of R, familiar with many popluar packages like pacman, tidyverse etc. Able
        to handle both basic data manipulation (e.g. importing, summary, cleaning...), visualisation (e.g. plotting and charting) 
        and complex operations (e.g. piping). Outstanding experience with statistic and data mining (regression anlysis). 
        Participated in projects like university enrollment analysis, used vehicle sales analysis and 
        travel agency seasonal sales analysis etc.`,
        modalStatus: false
    },
    {
        id: 4,
        waterMark: "04",
        title: "Stata",
        content: `Proficient user of Stata, able to conduct advanced econometrics analysis and data mining for 
                academic and industrial research. Attended many projects...`,
        blurColorBeg: "#89ff00",
        blurColorEnd: "#00bcd4",
        modalDetials: `Proficient user of Stata, able to conduct advanced econometrics analysis and data mining for 
                    academic and industrial research. Attended many research projects like stock valuation, asset pricing, 
                    corporate structure, and behavioural finance etc.`,
        modalStatus: false
    },
]

export default dataCardData;