import React from "react";
import Title from "react-vanilla-tilt";

function AnimatedCard(props) {
  const { title, subtitle, watermark, content } = props.card;
  const titleOptions = {
    max: 25,
    speed: 300,
    glare: true,
    "max-glare": 1,
  };
  return (
    <Title className="animatedCard" options={titleOptions} style={null}>
      <h1>{title}</h1>
      <div className="content">
        <h2>{watermark}</h2>
        <h3>{subtitle}</h3>
        <p>{content}</p>
      </div>
    </Title>
  );
}

export default AnimatedCard;
